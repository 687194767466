import React from 'react'
import loginImage from '../../assets/loginImage3.png';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/feketelogo.png';

function ContactUs() {

    const navigate = useNavigate();

  return (
    <div className='h-screen w-full flex items-center justify-center  bg-[#eeeeee] lg:px-0 px-6'>
        <div className='bg-white h-[617px] lg:w-[890px] w-full rounded-3xl flex flex-row items-center shadow-2xl lg:max-h-none max-h-[550px] lg:mt-0 mt-[-90px]'>
            <div className='lg:w-[50%] w-[0%] flex items-center justify-center'>
                <img src={ loginImage } alt="/"  className='h-[600px] shadow-2xl rounded-2xl'/>
            </div>
            <div className='lg:w-[50%] w-[100%] h-full flex flex-col items-center'>
                <div className='w-[300px] h-full flex flex-col lg:mt-20 mt-10'>
                    <p className='lg:text-4xl text-3xl text-center font-[OldStandardTT] mt-5'>CONTACT US</p>
                    
                    <p className='text-xs mt-10 lg:w-full w-[200px] mx-auto text-center font-[apple]'>If you encounter any issues or have any questions, we are here to help!</p>
                    <p className='text-xs mt-5 text-center font-[apple]'>You can reach us through the following channels</p>

                    <p className='text-xs text-center mt-5 font-[apple]'><span className='text-sm font-[apple-semibold]'>Email</span><br></br><span className='font-[apple-semibold] text-[#006454]'>info@azureh.com</span></p>
                    <p className='text-xs text-center mt-5 font-[apple]'><span className='text-sm font-[apple-semibold]'>Instagram</span><br></br><span className='font-[apple-semibold] text-[#006454]'>@azureh_official</span></p>
                    
                    <p className='text-xs mt-10 lg:w-full w-[200px] mx-auto text-center font-[apple]'>We look forward to hearing from you and are committed to providing solutions to any problems or inquiries you may have.</p>

                    <div className='h-[50px] w-[50px] bg-[#006454] rounded-full flex justify-center items-center mx-auto mt-10 cursor-pointer' onClick={ () => navigate("/") }>
                        <svg width="21" height="21" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.438 18.75 4.688 12l6.75-6.75"></path>
                            <path d="M5.625 12h13.688"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <img src={ logo } alt="/" className='absolute lg:h-[0px] h-[15px] bottom-10 opacity-40'/>
    </div>
  )
}

export default ContactUs
