import React, { useState } from 'react'

function ImageComp({ image }) {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setTimeout(() => {
        setIsHovered(true);
      }, 0);
    };

  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    function handleDownload(imageUrl) {
      const link = document.createElement('a');
      link.href = imageUrl.fileURL;
      link.download = imageUrl.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
  return (
    <div className='h-[450px] w-[250px] bg-white rounded-md overflow-hidden flex justify-center items-center shadow-2xl' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isHovered && (
        <div className='absolute w-[50px] h-[50px] rounded-full bg-white z-10 flex justify-center items-center cursor-pointer'>
            <button onClick={() => handleDownload(image) }>
            <svg width="20" height="20" fill="none" stroke="#606060" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m5.25 12.563 6.75 6.75 6.75-6.75"></path>
                <path d="M12 18.375V4.687"></path>
            </svg>
            </button>
        </div>
        )}
        <img src={ image.fileURL } alt="/" className={`h-full object-cover ${isHovered ? 'filter blur-md transition-all duration-300' : 'transition-all duration-300'}`}/>
    </div>
  )
}

export default ImageComp
