import React from 'react'
import loginImage from '../../assets/loginImage3.png';
import { Button } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAllCars } from '../../hooks/backend';
import MainLoading from './MainLoading';
import logo from '../../assets/feketelogo.png';

function MainPage() {

    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    
    const { cars, isLoading } = useAllCars();
    
    if (isLoading) return <MainLoading />;

    function handleSearch(data) {

        if(data.licenseplate !== "") {
            const hasLowerCase = /[a-z]/.test(data.licenseplate);
            if (hasLowerCase) {
                data.licenseplate = data.licenseplate.toUpperCase();
            }

            for (const car of cars) {
                if (car.licenseNumber === data.licenseplate) {
                    localStorage.setItem("license", data.licenseplate);
                    navigate("/delivery");
                    return;
                } else {
                    navigate("/notfound");
                }
            }
        }
    
    }

  return (
    <div className='h-screen w-full flex items-center justify-center  bg-[#eeeeee] lg:px-0 px-6'>
        <div className='bg-white h-[617px] lg:w-[890px] w-full rounded-3xl flex flex-row items-center shadow-2xl z-10 lg:max-h-none max-h-[550px] lg:mt-0 mt-[-90px]'>
            <div className='lg:w-[50%] w-[0%] flex items-center justify-center'>
                <img src={ loginImage } alt="/"  className='h-[600px] shadow-2xl rounded-2xl'/>
            </div>
            <div className='lg:w-[50%] w-[100%] h-full flex flex-col items-center'>
                <div className='w-[300px] h-full flex flex-col lg:mt-20 mt-10'>
                    <p className='lg:text-4xl text-3xl text-center font-[OldStandardTT] lg:mt-10 mt-20'>DELIVERY</p>
                    <p className='lg:text-xs text-[10px] text-center font-[apple] '>Enter your license plate number to access your photos.</p>
                    
                    <input type="text" id="helper-text" aria-describedby="helper-text-explanation" className="bg-gray-200 lg:mt-20 mt-32 font-[apple] lg:w-full w-[250px] mx-auto border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#006454] focus:border-[#006454] block p-2.5 text-center focus:outline-none" placeholder="License Plate Number" {...register("licenseplate")} required></input>
                    
                    <Button type="button" isLoading={ isLoading }  loadingText = { "Loading..." } class="text-white lg:mt-20 mt-10 font-[apple] bg-[#050708] lg:w-full w-[250px] mx-auto hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 mb-2" onClick={ handleSubmit(handleSearch) }>
                        Go
                    </Button>
                    <p className='text-xs text-gray-600 font-[apple] text-center'>By using the site, you accept the use of cookies.</p>

                    <div className='flex flex-row items-center justify-center lg:mt-36 mt-20'>
                        <p className='text-xs text-center text-[#006454] font-[apple] font-thin'>Do you have any problem?</p>
                        <p className='font-[apple-semibold] font-normal text-xs text-[#006454] cursor-pointer text-center ml-2' onClick={() => navigate("/contactus")}>Contact Us</p>
                    </div>
                </div>
            </div>
        </div>
        <img src={ logo } alt="/" className='absolute lg:h-[0px] h-[15px] bottom-10 opacity-40'/>
    </div>
  )
}

export default MainPage
