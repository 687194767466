import { signInWithEmailAndPassword } from "firebase/auth";
import { useSignOut } from "react-firebase-hooks/auth";
import { auth } from "../lib/firebase";
import { useEffect, useState } from "react";
import { LOGIN } from "../lib/routes";
import { useNavigate } from "react-router-dom";
import { uuidv4 } from "@firebase/util"
import {
    doc,
    setDoc,
    Timestamp,
    collection,
    getDocs,
    query,
    where,
    arrayUnion
  } from "firebase/firestore";
import { db } from "../lib/firebase";
import { useToast } from "@chakra-ui/react";
import { getDownloadURL, ref, uploadBytes, downloadBytes } from "firebase/storage";
import { storage } from "../lib/firebase";
import {
  useCollectionData
} from "react-firebase-hooks/firestore";


export function useLogin() {
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
  
    async function login({ email, password }) {
      setLoading(true);
  
      try {
        await signInWithEmailAndPassword(auth, email, password);
        setLoading(false);
        navigate("/server");
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  
    return { login, isLoading };
  }

  export function useLogout() {
    const [signOut, isLoading] = useSignOut(auth);
    const navigate = useNavigate();
  
    async function logout() {
      if (await signOut()) {
        navigate(LOGIN);
      }
    }
  
    return { logout, isLoading };
  }

  export function useAddCar() {
    
    const [licensePlate, setLicensePlate] = useState();
    const [clientName, setClientName] = useState();
    const [carModel, setCarModel] = useState();
    const [isLoading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const toast = useToast();

    async function addCar() {
        setLoading(true);

        if (!files.length) {
          setLoading(false);
          return;
        }
        
        const id = uuidv4();

        const fileDocRef = doc(db, "Cars", id);
        
        await setDoc(fileDocRef, {
            id,
            files: [],
            licenseNumber: licensePlate,
            clientName: clientName,
            carmodel: carModel,
            creationDate:  Timestamp.fromDate(new Date()),
        });

        const filesArray = [];

        for (const file of files) {
          const fileRef = ref(storage, `Car_Images/${licensePlate}/${file.name}`);
          await uploadBytes(fileRef, file);

          const fileURL = await getDownloadURL(fileRef);

          filesArray.push({
            filename: file.name,
            fileType: file.type,
            fileURL: fileURL,
          });
        }

        await setDoc(fileDocRef, {
          files: arrayUnion(...filesArray),
        }, { merge: true });

        toast({
            title: "Uploaded.",
            status: "success",
            isClosable: true,
            position: "top",
            duration: 2000,
          });

        setFiles([]);
        setLoading(false);
    }

    return { addCar, isLoading, setFiles, licensePlate, setLicensePlate, clientName, setClientName, carModel, setCarModel };
}

export function useCars(license = null) {
  console.log("licenseplate: " + license);
  const q = license
    ? query(
        collection(db, "Cars"),
        where("licenseNumber", "==", license)
      )
    : query(collection(db, "Cars"));
  const [cars, isLoading, error] = useCollectionData(q);
  if (error) throw error;
  //console.log("image path: " + cars[0].licenseNumber);
  return { cars, isLoading };
}

export function useFileCars(licenseNumber) {
  const q = collection(db, 'Cars').where('licenseNumber', '==', licenseNumber);
  const [cars, isLoading] = useCollectionData(q);
  
  return { cars, isLoading };
}


export function useAllCars() {
    const [cars, setCars] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const getCars = async () => {
            const carsRef = collection(db, "Cars");

            setLoading(true);

            try {
                const querySnapshot = await getDocs(carsRef);
                const carArray = [];

                querySnapshot.forEach((doc) => {
                    carArray.push(doc.data());
                });

                setCars(carArray);
                setLoading(false);
                console.log(carArray);
            } catch (error) {
                console.error("baj van, mily meglepo");
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        getCars();
    }, []);

    return { cars, isLoading };
}

export function downloadImages(){

  async function downloadImage(file) {
    await storage.bucket("cloud-be4a8.appspot.com").folder("Car_Images").folder("LUJZA_4").file(file.name).download();
  }

  return { downloadImage };
}


