import React, { useCallback, useState } from 'react'
import { LOGIN } from '../../lib/routes';
import { auth } from "../../lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { useAddCar } from '../../hooks/backend';
import { useDropzone } from 'react-dropzone';

function ServerPage() {

    const navigate = useNavigate();
    const [authUser, authLoading] = useAuthState(auth);
    const [selectedFileCount, setSelectedFileCount] = useState(0);


    const { isLoading: addingCar, addCar, setFiles, licensePlate, setLicensePlate, clientName, setClientName, carModel, setCarModel } = useAddCar();

    const onDrop = useCallback((acceptedFiles) => {
        setSelectedFileCount(acceptedFiles.length);
        setFiles(acceptedFiles);
      }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const uploadFiles = () => {
        if(selectedFileCount !== 0 && licensePlate !== "" && carModel !== "" && clientName !== "") {

          //setUploaderID("akosvecsei");

          addCar().then(() => {
            setFiles(null);
            setSelectedFileCount(0);  

            setLicensePlate('');
            setCarModel('');
            setClientName('');
           })
        }
      }
    

    if(!authLoading) {
        if(authUser === null) {
            navigate(LOGIN);
        } else {
            return (
                <div className='h-screen w-full flex items-center justify-center  bg-[#eeeeee] lg:px-0 px-12'>
                  <div className='h-[610px] w-[600px] bg-white rounded-2xl shadow-2xl flex flex-col p-10'>
                    <div className="flex items-center justify-center w-full">
                        <div {...getRootProps()} className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 ">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                    </svg>
                                    {selectedFileCount !== 0 ? (
                                        <>
                                            <p className="mb-2 text-sm text-gray-500 font-[apple-semibold]">Ready to upload.</p>
                                            <p className="text-xs text-gray-500  font-[apple]">{ `${selectedFileCount} file(s) selected` }</p>
                                        </>
                                    ) : (
                                        <>
                                            <p className="mb-2 text-sm text-gray-500 font-normal font-[apple-semibold]">Click to upload</p>
                                            <p className="text-xs text-gray-500  font-[apple]">SVG, PNG, JPG or GIF</p>
                                        </>
                                    )}
                                </div>
                            <input {...getInputProps()} />
                        </div>
                    </div>

                    <input type="text" id="helper-text" aria-describedby="helper-text-explanation" className="bg-gray-100 mt-4 border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 text-center font-[apple]" placeholder="Client's name" required={ true } value={clientName} onChange={(e) => setClientName(e.target.value)}></input>
                    <input type="text" id="helper-text" aria-describedby="helper-text-explanation" className="bg-gray-100 mt-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#006454] focus:border-[#006454] block w-full p-2.5 text-center font-[apple]" placeholder="Car model" required={ true } value={carModel} onChange={(e) => setCarModel(e.target.value)}></input>
                    <input type="text" id="helper-text" aria-describedby="helper-text-explanation" className="bg-gray-100 mt-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#006454] focus:border-[#006454] block w-full p-2.5 text-center font-[apple]" placeholder="License plate number" required={ true } value={licensePlate} onChange={(e) => setLicensePlate(e.target.value)}></input>

                    <Button type="button" isLoading={addingCar} loadingText="Uploading..." fontWeight={'normal'} backgroundColor={'black'} color={'white'} _hover={{ bg: '#333333' }} className=" text-white mt-16 bg-[#050708] font-[apple] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 mb-2" onClick={ uploadFiles }>
                        Upload
                    </Button>
                  </div>
                </div>
            )
        }
    } else {
        return (
            <div>
                Loading...
            </div>
        );
    } 
    

  
}

export default ServerPage
