import React from 'react'
import loginImage from '../../assets/loginImage3.png';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/feketelogo.png';

function Sorry() {

  const navigate = useNavigate();

  return (
    <div className='h-screen w-full flex items-center justify-center  bg-[#eeeeee] lg:px-0 px-6'>
        <div className='bg-white h-[617px] lg:w-[890px] w-full rounded-3xl flex flex-row items-center shadow-2xl lg:max-h-none max-h-[550px] lg:mt-0 mt-[-90px]'>
            <div className='lg:w-[50%] w-[0%] flex items-center justify-center'>
                <img src={ loginImage } alt="/"  className='h-[600px] shadow-2xl rounded-2xl'/>
            </div>
            <div className='lg:w-[50%] w-[100%] h-full flex flex-col justify-center items-center'>
                <div className='w-[300px] h-full flex flex-col lg:mt-20 mt-10'>
                    <p className='lg:text-4xl text-3xl text-center font-[OldStandardTT] lg:mt-16 mt-10'>WE APOLIGIZE</p>
                    
                    <p className='text-xs lg:mt-10 mt-20 text-center lg:w-full w-[200px] mx-auto font-[apple]'>It's possible there might be a typo in the license plate number, you provided.</p>
                    <p className='text-xs mt-5 text-center lg:w-full w-[200px] mx-auto font-[apple]'>OR</p>
                    <p className='text-xs mt-5 text-center lg:w-full w-[200px] mx-auto font-[apple]'>The images of your car are still undergoing editing.</p>

                    <p className='text-xs mt-0 text-center lg:w-full w-[200px] mx-auto font-[apple]'>This process may require some time.</p>
                    <p className='text-xs mt-5 text-center lg:w-full w-[200px] mx-auto font-[apple]'>Thank you for your patience and understanding as we finalize the edits.</p>

                    <div className='h-[50px] w-[50px] bg-[#006454] rounded-full flex justify-center items-center mx-auto lg:mt-10 mt-14 cursor-pointer' onClick={ () => navigate("/") }>
                        <svg width="21" height="21" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.438 18.75 4.688 12l6.75-6.75"></path>
                            <path d="M5.625 12h13.688"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <img src={ logo } alt="/" className='absolute lg:h-[0px] h-[15px] bottom-10 opacity-40'/>
    </div>
  )
}

export default Sorry
