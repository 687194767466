import React from 'react'
import logo from '../../assets/feketelogo.png'
import ImageComp from './ImageComp'
import { useCars } from '../../hooks/backend'
import FullScreenLoading from './FullScreenLoading';

function DeliveryPage() {

    const license = localStorage.getItem("license");
    const { cars, isLoading } = useCars(license);

    if(!cars || isLoading) {
      return <FullScreenLoading />;
    } else {
      return (
        <div className='h-full w-full flex flex-col items-center bg-[#eeeeee] lg:px-0 px-6'>
            <p className='font-[OldStandardTT] lg:text-4xl text-3xl mt-16'>TIME TO REVEAL</p>
            <p className='lg:text-sm text-xs lg:w-full w-[250px] mx-auto mt-10 text-center font-[apple]'>We trust you'll be delighted with the images,<br></br> each meticulously crafted to showcase the essence of your car. </p>
            <p className='lg:text-sm text-xs lg:w-full w-[250px] mx-auto mt-5 text-center font-[apple]'>Thank you for entrusting us with this important task,<br></br> and should you have any further needs, please don't hesitate to reach out.</p>
            <p className='lg:text-sm text-xs lg:w-full w-[250px] mx-auto mt-5 text-center text-[#006454] font-[apple]'>Your satisfaction is our priority.</p>
    
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-3 md:gap-4 gap-6 mt-16 pb-20'>
              {cars[0]?.files.map((fileInfo, index) => (<ImageComp key = { index } image = { fileInfo } />))}
            </div>
    
            <img src={ logo } alt="/" className='absolute bottom-5 h-[15px] opacity-50'/>
        </div>
      )
    }
}

export default DeliveryPage
