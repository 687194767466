import React from 'react'
import { Spinner } from "@chakra-ui/react";

function FullScreenLoading() {
  return (
    <div className='h-screen w-full flex items-center justify-center bg-[#eeeeee]'>
      <Spinner color='black' size={ "lg" }></Spinner>
    </div>
  )
}

export default FullScreenLoading
