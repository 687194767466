import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAjT588wJbg6Esos9M8kUc-yUWbzSlSYgM",
    authDomain: "cloud-be4a8.firebaseapp.com",
    projectId: "cloud-be4a8",
    storageBucket: "cloud-be4a8.appspot.com",
    messagingSenderId: "598963686738",
    appId: "1:598963686738:web:1d90890b51a25763c6be8b",
    measurementId: "G-KJFDWDW1V5"
  };


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);