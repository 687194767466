import { createBrowserRouter } from "react-router-dom";

import MainPage from "../components/client/MainPage";
import LoginPage from "../components/server/LoginPage";
import ServerPage from "../components/server/ServerPage";
import DeliveryPage from "../components/client/DeliveryPage";
import ContactUs from "../components/client/ContactUs";
import Sorry from "../components/client/Sorry";

export const ROOT = "/";
export const LOGIN = "login";
export const SERVER = "server";
export const DELIVERY = "delivery";
export const CONTACT = "contactus";
export const SORRY = "notfound";

export const router = createBrowserRouter([
    { path: ROOT, element: <MainPage /> },
    { path: LOGIN, element: <LoginPage /> },
    { path: SERVER, element: <ServerPage /> },
    { path: DELIVERY, element: <DeliveryPage /> },
    { path: CONTACT, element: <ContactUs /> },
    { path: SORRY, element: <Sorry /> },
  ]);