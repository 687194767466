import React from 'react'
import { Spinner } from "@chakra-ui/react";
import loginImage from '../../assets/loginImage3.png';

function MainLoading() {
  return (
    <div className='h-screen w-full flex items-center justify-center  bg-[#eeeeee] lg:px-0 px-6'>
        <div className='bg-white h-[617px] lg:w-[890px] w-full rounded-3xl flex flex-row items-center shadow-2xl lg:max-h-none max-h-[550px] lg:mt-0 mt-[-90px]'>
            <div className='lg:w-[50%] w-[0%] flex items-center justify-center'>
                <img src={ loginImage } alt="/"  className='h-[600px] shadow-2xl rounded-2xl'/>
            </div>
            <div className='lg:w-[50%] w-[100%] h-full flex flex-col justify-center items-center'>
                <Spinner color='black' size={ "lg" }></Spinner>
            </div>
        </div>
    </div>
  )
}

export default MainLoading
