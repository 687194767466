import React from 'react';
import { useLogin } from '../../hooks/backend';
import { useForm } from "react-hook-form";
import logo from '../../assets/feketelogo.png';
import loginImage from '../../assets/loginImage.png';
import { Button } from "@chakra-ui/react";

function LoginPage() {

    const { login, isLoading } = useLogin();
    const {
      register,
      handleSubmit
    } = useForm();
  
    async function handleLogin(data) {
      login({
        email: data.email,
        password: data.password
      });
    }

  return (
    <div className='h-screen w-full flex items-center justify-center  bg-[#eeeeee] lg:px-0 px-12'>
        <div className='bg-white h-[617px] lg:w-[890px] w-full rounded-3xl flex flex-row items-center shadow-2xl'>
            <div className='w-[50%] flex items-center justify-center'>
                <img src={ loginImage } alt="/"  className='h-[600px] shadow-2xl'/>
            </div>
            <div className='w-[50%] h-full flex flex-col items-center'>
                <img src={ logo } alt="/" className='h-[15px] mx-auto mt-10 opacity-50'/>
                <div className='w-[300px] h-full flex flex-col mt-20'>
                    <p className='text-4xl text-center font-[OldStandardTT]'>Welcome Back</p>
                    <p className='text-[10px] text-center font-[apple]'>Enter your email-and password to access your account</p>
                    <p className='mt-16 text-xs pb-1 ml-2 font-[apple]'>E-mail</p>
                    <input type="text" id="helper-text" aria-describedby="helper-text-explanation" className="bg-gray-200 font-[apple]  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#006454] focus:border-[#006454] block w-full p-2.5  " placeholder="Enter your email" {...register("email")}></input>
                    <p className='mt-7 text-xs pb-1 ml-2 font-[apple]'>Password</p>
                    <input type="password" id="helper-text" aria-describedby="helper-text-explanation" className="bg-gray-200 font-[apple] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#006454] focus:border-[#006454] block w-full p-2.5 " placeholder="Enter your password" {...register("password")}></input>
                    <Button type="button" isLoading={isLoading} loadingText="Logging In" fontWeight={'normal'} backgroundColor={'black'} color={'white'} _hover={{ bg: '#333333' }} className="text-white mt-16 font-[apple] bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 mb-2" onClick={ handleSubmit(handleLogin) }>
                        Sign in
                    </Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LoginPage
